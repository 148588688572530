import type { ZudokuConfig } from "zudoku";

const config: ZudokuConfig = {
  topNavigation: [{ id: "api", label: "API Reference" }],
  redirects: [{ from: "/", to: "/api" }],
  apis: {
    type: "file",
    input: "./apis/openapi.yaml",
    navigationId: "api",
  },
  page: {
    logo: {
      src: {
        dark: "https://www.pvalines.com/assets/img/logo-1.png",
        light: "https://www.pvalines.com/assets/img/logo-1.png",
      },
    },
  },
  metadata: {
    applicationName: "PVALines",
    title:
      "PVALines - Leading Non-VoIP Number Provider for SMS Verification | API Docs",
    description:
      "PVALines offers reliable non-VoIP numbers for secure SMS verification, global eSIM services, and residential proxy solutions. Trusted for seamless and fast authentication processes",
    keywords: [
      "non-voip number",
      "sms verification",
      "eSIM",
      "residential proxy",
      "PVALines",
      "phone",
      "verification",
      "secure authentication",
      "virtual number",
    ],
    favicon: "https://www.pvalines.com/assets/img/favicon-1.svg",
    logo: "https://www.pvalines.com/assets/img/logo-1.png",
  },
};

export default config;
